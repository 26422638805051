<template>
<div class="wrap">
  <router-view/>
  <div class="mask" v-if="maskOpen" :class="{open:menuOpened}"  @click="clickMenu()" ></div>
<!--  <svg @click="clickMenu()"  class="menu-ico" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M170.666667 213.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" ></path><path d="M170.666667 512m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" ></path><path d="M170.666667 810.666667m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z"  ></path><path d="M896 778.666667H362.666667c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h533.333333c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32zM362.666667 245.333333h533.333333c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H362.666667c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32zM896 480H362.666667c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h533.333333c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32z"  ></path></svg>-->
<!--  <i @click="clickMenu()" class="menu-ico"  />-->
  <img src="" alt="" @click="clickMenu()" class="icon menu-ico" >
  <div class="menu-wrap" :class="{opened:menuOpened}" v-if="user" >
    <div class="header-wrap">
      <div class="info">
        <img :src="user.avatar" alt="" class="icon header" >
        <div>
          <div>{{user.name}}</div>
          <div>{{user.phone|phoneSecret}}</div>
        </div>
      </div>
<!--      <svg  class="close" viewBox="0 0 1076 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5352" ><path d="M1074.977 463.141l1.763 1.396q-0.869-0.735-1.763-1.396zM0 465.15l3.184-2.523c-3.822 2.659-6.942 6.058-9.205 10.009z" p-id="5353"></path><path d="M111.983 994.893c-0.282 1.886-0.576 3.759-0.833 5.657 0.53-2.012 0.843-4.326 0.86-6.71zM83.758 1021.833zM55.569 991.477q0.306-2.118 0.637-4.237c-0.43 1.794-0.687 3.859-0.71 5.981z" p-id="5354"></path><path d="M1021.931 984.901c0.355 2.216 0.673 4.445 0.992 6.674-0.088-2.676-0.521-5.217-1.258-7.625zM965.272 999.46c-0.245-1.751-0.502-3.49-0.771-5.229 0.137 2.393 0.541 4.636 1.182 6.776z" p-id="5355"></path><path d="M538.634 0c-282.327 0-512 229.673-512 512s229.673 512 512 512 512-229.673 512-512-229.673-512-512-512zM538.634 950.516c-241.808 0-438.528-196.757-438.528-438.528s196.721-438.516 438.528-438.516 438.528 196.721 438.528 438.528-196.757 438.528-438.528 438.528z" p-id="5356"></path><path d="M748.554 305.446c-6.631-6.706-15.832-10.858-26.003-10.858-10.025 0-19.107 4.034-25.712 10.568l-158.353 156.638-155.944-156.458c-6.621-6.639-15.777-10.747-25.893-10.747-20.195 0-36.566 16.371-36.566 36.566 0 10.079 4.078 19.206 10.674 25.82l155.723 156.249-156.997 155.258c-6.704 6.632-10.856 15.833-10.856 26.003 0 20.197 16.373 36.571 36.571 36.571 10.027 0 19.112-4.036 25.718-10.571l157.214-155.512 158.466 159.030c6.568 6.345 15.524 10.255 25.393 10.255 20.194 0 36.564-16.37 36.564-36.564 0-9.814-3.867-18.726-10.16-25.293l-158.27-158.833 158.136-156.409c6.706-6.631 10.858-15.832 10.858-26.003 0-10.025-4.034-19.107-10.568-25.712z" p-id="5357"></path></svg>-->
<!--      <i @click="clickMenu()" class="icon close"  />-->
      <img src="" alt="" @click="clickMenu()" class="icon close">
    </div>
    <div class="menu">
      <div class="item" @click="clickHome()">
        <span>首页</span>
        <i class="icon cell"/>
      </div>
<!--      <div class="item">-->
<!--        <span>中奖记录</span>-->
<!--        <i class="icon cell"/>-->
<!--      </div>-->
      <div class="item-wrap">
        <div class="title">互动列表</div>
        <div class="item" v-for="(v,i) in flows" :key="i" @click="clickJump(v)" >
          <span>{{v.name}}</span>
          <i class="icon cell"/>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>

import api from "@/utils/api";
export default {
  name: "CheckinView",
  data(){
    return {
      menu:'',
      phone:"18628128523",
      menuOpened:false,
      maskOpen:false,
      user:undefined,
      flows:[],
      actionDic:{checkin_wall:"CheckinChat",vote:"Vote",lottery:"Lottery"}
    }
  },
  beforeRouteUpdate(to, from, next) {
    if(to.meta.menu){
      this.menu = to.meta.menu
    }
    console.error(to.fullPath,from.fullPath)
    if (to.fullPath != from.fullPath){
      next()
    }
  },
  mounted() {

    this.loadData()
  },
  methods:{
    loadData(){
      this.loadUser()
      this.loadFlows()
      this.loadTitle()
    },
    loadUser(){
      api.get_userinfo().then(res=>{
        this.user = res;
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    loadFlows(){
      api.get_flows().then(res=>{
        this.flows = res
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    loadTitle(){
      api.get_activity().then(res=>{
        document.title = res.activity.name
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    checkMask(){
      setTimeout(()=>{
        this.maskOpen = this.menuOpened;
      },300)
    },
    clickMenu(){

      if(!this.menuOpened){
        this.maskOpen = !this.menuOpened;
      }else {
        this.checkMask()
      }
      this.menuOpened = !this.menuOpened;

    },
    clickHome(){
      this.clickMenu()
      this.$router.push({name:'Index'});
    },
    clickVote(){
      this.clickMenu()
      this.$router.push({name:'Vote',query:{fid:123}});
    },
    clickJump(flow){
      this.clickMenu()
      const key = flow.sketch.project_key
      const routName = this.actionDic[key]
      console.error(routName,key)
      this.$router.push({name:routName,params:{fid:flow.id}})
    }
  },
}
</script>


<style lang="scss" scoped>

.mask {
  position: fixed;
  left: 0;bottom: 0;right: 0;top: 0;
  background-color: rgba(0,0,0,0);
  transition: all .3s ease-in-out;
  &.open {
    background-color: rgba(0,0,0,.5);
  }
}


.menu-ico {
  position: absolute;
  left: 10px;top: 10px;
  content: url("@/assets/images/menu.png");
  width: 25px;height: 25px;
  padding: 5px;
}

.wrap {
  position: fixed;
  top: 0;right: 0;bottom: 0;left: 0;
  background-color: #ededed;
}



.menu-wrap {
  position: fixed;
  top: 0;bottom: 0;left: -300px;
  background-color: white;
  padding: 15px 10px;
  font-size: 14px;
  min-width: 210px;
  transition: all 0.3s;
  &.opened {
    left: 0;
  }

  &>.header-wrap {
    display: flex;
    justify-content: space-between;
    &>.info {
      display: flex;align-items: center;
      &>.header {
        width: 50px;height: 50px;
        border-radius: 50%;
        margin-right: 5px;
      }
      text-align: left;
    }
    &>.close {
      width: 25px;height: 25px;
      padding: 3px;
      content: url("@/assets/images/close.png");
    }
  }


  &>.menu {
    margin-top: 15px;
    text-align: left;

    .item-wrap { margin-left: 10px;margin-top: 10px;}
    .item {
      margin-left: 10px;
      padding: 8px 0;
      display: flex;justify-content: space-between;align-items: center;
      &>i.cell {
        background-image: url("@/assets/images/arrow_right.png");
        height: 12px;width: 12px;
      }
    }
    .title {
      color: #aaa;
    }
  }

}



</style>