import http from "./http";
import {logout,KEY_TOKEN} from "./http";
import mqtt from 'mqtt';
import {random,base64_encode,base64_decode} from './helper'




function login(token) {
    // console.error('token',token)
    sessionStorage.setItem(KEY_TOKEN,token)
}

let mqtt_client = undefined
const subscribeMap = {}
// {topic_base64:{callbackId:onMessage}}

function subscribe(topic,onMessage){

    const topic_base64 = base64_encode(topic)
    let callbackMap = subscribeMap[topic_base64]
    if (callbackMap == undefined){
        callbackMap = {}
    }
    const callbackId = `${random()}_${topic_base64}`
    callbackMap[callbackId] = onMessage
    subscribeMap[topic_base64] = callbackMap
    mqtt_client.subscribe(topic,err=>{
        if(err) {
            console.error(`订阅${topic}失败,${err}`)
        }
    })
    return callbackId
}
function unsubscribe(callbackId){
    if(callbackId == undefined)return;
    const infos = callbackId.split('_')
    if(infos.length !=2) return;
    const topic_base64 = infos[1]
    const topic = base64_decode(topic_base64)
    mqtt_client.unsubscribe(topic,err=>{
        if(err) {
            console.error(`解除订阅${topic}失败,${err}`)
        }
    })
    let callbackMap = subscribeMap[topic_base64]
    if (callbackMap == undefined){
        return
    }
    delete callbackMap[callbackId]
}


function login_mqtt() {
    if (!api.logined)
        return
    if ( mqtt_client)
        return;

    const token = sessionStorage.getItem(KEY_TOKEN)
    const body = JSON.parse(new Buffer(token.split('.')[1],'base64').toString('utf-8'))
    // console.log(token,body,body.uid,body.mqtt_pwd)

    const options = {
        clean:true,
        connectTimeout:4000,
        clientId:`client_${random()}`,
        username:body.uid,
        password:body.mqtt_pwd,
        keepalive:60,
    }
    // console.error("options",options)
    // console.error(typeof options.username,typeof options.password)
    // console.error(options.username,options.password)


    // const  connectUrl = 'ws://1.14.99.195:8083/mqtt'
    mqtt_client = mqtt.connect(process.env.VUE_APP_EMQX_URL,options)



    mqtt_client.on('connect',(error) => {
        console.error('正在连接',error)
    })
    mqtt_client.on('reconnect',(error) => {
        console.error('正在重连',error)
    })
    mqtt_client.on('error', (error) => {
        // console.error('mqtt error:', error)
        // console.error('mqtt error111111111111:',error.name)
        // console.error('mqtt error12222222222:',error.message)
        // mqtt_client.reconnect()
        console.error('mqtt error:', error)
        if(error.message.includes('Not authorized')||error.message.includes('Bad username or password')){
            logout()
            window.location.reload()
        }
    })
    mqtt_client.on('disconnect', (error) => {
        console.error('mqtt disconnect:', error)
        // mqtt_client.reconnect()
    })
    mqtt_client.on('close', (error) => {
        console.error('mqtt close:', error)
        // mqtt_client.reconnect()
    })
    mqtt_client.on('offline', (error) => {
        console.error('mqtt offline:', error)
        // mqtt_client.reconnect()
    })


    mqtt_client.on('message', (topic, message) => {
        // console.log('收到消息：', topic, message.toString())
        // {topic_base64:{callbackId:onMessage}}
        const topic_base64 = base64_encode(topic)
        const callbackMap = subscribeMap[topic_base64]
        if (callbackMap == undefined)return
        // console.error('callbackMap',callbackMap)
        for (const callbackId in callbackMap){
            try {
                callbackMap[callbackId](JSON.parse(message.toString()))
            }catch (e) {
                console.error(`onMessage出错,${topic}`)
            }
        }
    })
    // mqtt_client.subscribe('/*')
}






/*
* 主要接口
* */

async function get_error(){
    const params = {}
    return await http.get('/wx/error',{params})
}
async function get_auth_url(){
    const params = {}
    return await http.get('/wx/auth_url',{params})
}

async function get_userinfo(){
    const params = {}
    return await http.get('/wx/userinfo',{params})
}

async function get_flows(){
    const params = {}
    return await http.get('/wx/flows',{params})
}

async function get_checkininfo(){
    const params = {}
    return await http.get('/wx/checkin_info',{params})
}

async function get_activity(){
    const params = {}
    return await http.get('/wx/activity',{params})
}


async function do_checkin(phone){
    const data = {phone}
    return await http.post(`/wx/checkin`,data)
}
async function do_checkin_bless(fid,blessing){
    const data = {fid,blessing}
    return await http.post(`/wx/checkin/bless`,data)
}

async function get_ballots(fid){
    const params = {fid}
    return await http.get('/wx/vote/ballots',{params})
}
async function get_blessings(){
    const params = {}
    return await http.get('/wx/blessings',{params})
}

async function get_palyes(fid){
    const params = {fid}
    return await http.get('/wx/vote/players',{params})
}
async function get_statistics(fid){
    const params = {fid}
    return await http.get('/wx/vote/statistics',{params})
}
async function do_vote(rbid,player_pid){
    const data = {rbid,player_pid}
    return await http.post(`/wx/vote`,data)
}

const api_main = {get_userinfo,get_flows,get_activity,get_checkininfo,do_checkin_bless,get_blessings,get_error,get_auth_url,do_checkin,get_ballots,get_statistics,get_palyes,do_vote}


const api = {
    login_mqtt,
    login,
    logout,
    subscribe,
    unsubscribe,
    ...api_main,
}

// Object.defineProperty(api,'mqtt_client',{
//     get(){
//         return mqtt_client
//     }
// })

Object.defineProperty(api,'logined',{
    get(){
        let token = sessionStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            return true
        }
        return false
    }
})

Object.defineProperty(api,'role',{
    get(){
        let token = sessionStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            return true
        }
        return false
    }
})



export default api