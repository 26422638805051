function split(value,sep=',') {
    if (typeof value != 'string')
        return [value]
    return value.split(sep)
}

function imageStyle(url) {
    const style = {"background-image":`url(${url})`}
    return style
}

function dateFormat(timestamp,format='yyyy/MM/dd hh:mm:ss'){
    const date = new Date(timestamp*1000)
    const formatMap = {
        yyyy:date.getFullYear(),
        MM:date.getMonth()+1,
        dd:date.getDate(),
        hh:date.getHours(),
        mm:date.getMinutes(),
        ss:date.getSeconds()}
    let result = format
    for (const key in formatMap){
        result = result.replaceAll(key,formatMap[key])
    }
    return result
}
function toPrecent(value,fixed=0){
    const res = (value*100).toFixed(fixed)
    return `${res}%`
}

function timeFormat(milliseconds){
    const ms = milliseconds %1000
    const s = Math.floor(milliseconds/1000) % 60
    const m = Math.floor(milliseconds/(60*1000))%60
    const h = Math.floor(milliseconds/(60*60*1000))
    return `${h}:${m}:${s}'${ms}`
}


function phoneSecret(phone){
    const frond = phone.slice(0,3)
    const end = phone.slice(-4)
    return `${frond}****${end}`
}

function omit(text,len=7){

    if(text.length<len){
        return text
    }
    const flen = Math.floor(len /2)
    const elen = len - flen
    const frond = text.slice(0,flen)
    const end = text.slice(-elen)
    return `${frond}..${end}`
}


const filters = {split,imageStyle,dateFormat,toPrecent,timeFormat,phoneSecret,omit}

export function init(vueclass) {
    for (const k in filters){
        vueclass.filter(k,filters[k])
    }
    vueclass.prototype.util = filters
}