import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/styles/global.scss'

import 'amfe-flexible';
import 'vant/lib/icon/index.css';
import 'vant/lib/toast/index.css';

import { Toast } from 'vant';
Vue.use(Toast);

import 'vant/lib/dialog/index.css'
import { Dialog } from 'vant';
Vue.use(Dialog);




Vue.config.productionTip = false



import { init as filter_init } from "./filters";
filter_init(Vue)



Vue.prototype.notice = function (msg_obj,type){
  let msg = msg_obj
  if (typeof(msg_obj) == 'object' && msg_obj.html){
    msg = msg_obj.content
  }


  const message = `${msg}`
  if (type === 'success'){
    console.error("success")
    Toast.success(message)
  }else if(type === 'warning'){
    console.error("warning")
    Toast.fail(message)
  }else if(type === 'info'){
    console.error("info")
    Toast(message)
  }

}
Vue.prototype.notice_warning  = function (msg_obj){
  this.notice(msg_obj,'warning')
}
Vue.prototype.notice_success  = function (msg_obj){
  this.notice(msg_obj,'success')
}
// Vue.prototype.notice_error  = function (msg_obj){
//   this.notice(msg_obj,'error','错误')
// }
Vue.prototype.notice_info  = function (msg_obj){
  this.notice(msg_obj,'info')
}



new Vue({
  router,

  // vue-router,
  render: h => h(App)
}).$mount('#app')
