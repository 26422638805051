<template>
<div class="wrap">
  <i class="bg" :style="bgStyle" v-if="data" />
  <div class="content-wrap" v-if="data">
    <div class="title">{{data.title}}</div>
    <img class="header" :src="data.temp_user.headimgurl"/>
    <div class="name">{{data.temp_user.nickname}}</div>
    <input class="input" type="text" v-model="phone" />
    <div class="btn" @click="clickSure()">签到</div>
  </div>
</div>
</template>

<script>
import api from "@/utils/api";


export default {
  name: "CheckinView",
  data(){
    return {
      phone:"",
      data:undefined
    }
  },
  computed:{
    bgStyle(){
      return `background-image:url("${this.data.temp_user.headimgurl}");filter: blur(16px);`
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_checkininfo().then(data=>{
        this.data=data
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    clickSure(){
      // console.log(weui)
      const phone = this.phone.trim()
      if(phone.length!=11){
        this.notice_warning("手机号格式有误")
        return
      }
      api.do_checkin(this.phone).then(res=>{
        location.href = res.jump
      }).catch(err=>{
        this.notice_warning(err)
      })

    }
  },
}
</script>

<style lang="scss" scoped>

.wrap {
  position: fixed;
  top: 0;right: 0;bottom: 0;left: 0;
  background-color: lightgrey;

  i.bg {
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    background-image: url("@/assets/images/checkin_sj.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  .content-wrap {
    margin-top: 20%;
    text-align: center;
    color: white;
    font-size: 16px;
    display: flex;flex-direction: column;align-items: center;
    &>.title {
      font-size: 20px;
    }
    .header {
      display: inline-block;
      width: 100px;height: 100px;
      border-radius: 50%;
      object-fit: cover;

      margin-top: 30px;
      box-shadow: 0 0 20px white;
    }
    .name {
      margin-top: 4px;
    }
    .input {
      margin-top: 30px;
      background-color: white;
      border: none;
      padding: 10px; border-radius: 3px;
    }
    .btn {
      margin-top: 10px;
      padding: 8px 20px;border-radius: 4px;
      background-color: #3a76d5;
    }
  }


}



</style>