<template>
<div class="content-wrap">

  <div class="nav">
    <div class="title">
      投票结果
    </div>
    <div class="actions">
      <img class="icon back" alt="返回" @click="$router.go(-1)" />
    </div>
  </div>
  <div class="rank-box">
    <div class="item" v-for="(v,i) in datas" :key="i">
      <div class="rank">
        <img src="" alt="" class="icon" v-if="i<3" />
        <span v-else>{{i+1}}</span>
      </div>
      <img :src="v.avatar" alt="" class="icon avatar"/>
      <div class="long">
        <div class="name">{{v.name}}</div>
        <div class="progress-box">
          <div class="value" :style="value_style_for(v.id)" ></div>
        </div>
      </div>
      <div class="score-box">
        <div>
          <span >{{v.p}}</span>
          <span class="unit">票</span>
        </div>
        <div>
          <span >{{v.score}}</span>
          <span class="unit">分</span>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "VoteResult",
  props:["fid"],
  data(){
    return {
      datas:[]
    }
  },
  mounted() {
    this.loadData()
  },
  computed:{
    total(){
      let r = 0
      for(const p of this.datas){
        r += p.score
      }
      return r
    }
  },
  methods:{
    score_for(id){
      for(const p of this.datas){
        if(p.id === id){
          return p.score
        }
      }
      return 0
    },
    value_style_for(id){
      if(this.total >0){
        const score = this.score_for(id)
        const r = (score/this.total)*100
        return  `width:${r}%`
      }
      return 'width:0'
    },
    loadData(){
      console.error(111,this.fid)
      api.get_statistics(this.fid).then(data=>{
        this.datas = data
      }).catch(err=>{
        this.notice_warning(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>




.content-wrap {
  height: 100vh;
  overflow-y: auto;
  padding: 54px 10px;box-sizing: border-box;

  font-size: 14px;
}

.nav {
  position: fixed;
  top: 0;right: 0;left: 0;
  height: 44px;background-color: white;
  display: flex; align-items: center;justify-content: center;
  font-size: 16px;
  &>.actions {
    position: absolute;
    top: 0;right: 0;left: 0;bottom: 0;
    display: flex; align-items: center;justify-content: space-between;

    &>img.back {
      content: url("@/assets/images/back.png");
      height: 18px;
      padding: 10px;
    }
  }
}


.item {
  width: 100%;
  display: flex;
  //background-color: red;
  &+.item { margin-top: 15px}
  &>*+*{margin-left: 10px;}
  @for $i from 1 through 3 {
    &:nth-child(#{$i}){
      &>.rank>img.icon {
        content: url("@/assets/images/r#{$i}.png");
      }
    }
  }

  &>.rank {
    display: flex;align-items: center;justify-content: center;
    font-size: 16px;
    width: 28px;
    &>img.icon {
      height: 30px;
      object-fit: cover;
    }
  }

  img.avatar {

    object-fit: cover;
    width: 44px;height: 44px;
    border-radius: 50%;
    border: 2px solid white;

  }

  &>.long {
    flex: 1;
    display: flex;flex-direction: column;justify-content: center;align-items: flex-start;
    &>.progress-box {
      margin-top: 5px;
      height: 8px;
      width: 100%;

      position: relative;
      &>.value {
        position: absolute;
        z-index: -1;
        left: 0;top: 0;bottom: 0;
        border-radius: 4px;
        background: linear-gradient(to right, #4999f7 0%, #aae4fc 100%);
        width: 0;
      }
    }
  }

  .score-box {
    display: flex;flex-direction: column;
    align-items: center;justify-content: center;
  }
  .unit {
    margin-left: 5px;
    color: #888;
  }

}


</style>