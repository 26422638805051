import Vue from 'vue'
import VueRouter from 'vue-router'

import Error from "@/views/Error.vue";
import Auth from "@/views/Auth.vue";
import Checkin from "@/views/Checkin.vue";
import CheckinChat from "@/views/CheckinChat.vue";
import Main from "@/views/Main.vue";
import Index from "@/views/Index.vue";
import Vote from "@/views/Vote.vue";
import VoteResult from "@/views/VoteResult.vue";



const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

Vue.use(VueRouter)


const routes = [
    { path: '/', redirect: '/main/index' },
    {
        path: '/main',
        name: 'Main',
        component: Main,
        // meta:{public:true}
        children:[
            {
                path: 'index',
                props:true,
                name: 'Index',
                component: Index,
                // meta:{roles:['user']}
                meta:{menu:'index'}
            },
            {
                path: 'checkin/:fid',
                props:true,
                name: 'CheckinChat',
                component: CheckinChat,
            },
            {
                path: 'vote/:fid',
                props:true,
                name: 'Vote',
                component: Vote,
                meta:{menu:'vote'}
            },
        ]
    },
    {
        path: '/vote_result/:fid',
        props:true,
        name: 'VoteResult',
        component: VoteResult,
        // meta:{public:true}
    },
    {
        path: '/checkin/:fid',
        props:true,
        name: 'Checkin',
        component: Checkin,
    },

    {
        path: '/auth',
        name: 'Auth',
        props: true,
        component: Auth,
    },
    {
        path: '/error',
        name: 'Error',
        props: true,
        component: Error,
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
