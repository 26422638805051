<template>
<div class="wrap">
  <i class="bg"  />
  <div class="title" v-if="data">{{data.title}}</div>
  <div class="tip-wrap">
    <div class="tip-box">
      <div >需要授权获取个人信息（头像，昵称）才可继续访问</div>
      <div class="btn" @click="clickJump()" >去授权></div>
    </div>
  </div>
</div>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "AuthView",
  data(){
    return {
      data:undefined,
      jump:undefined,
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_checkininfo().then(data=>{
        this.data=data
      }).catch(err=>{
        this.notice_warning(err)
      })

      api.get_auth_url().then(res=>{
        this.jump = res.jump
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    clickJump(){
      location.href=this.jump
    }
  },
}
</script>

<style lang="scss" scoped>

i.bg {
  position: absolute;
  top: 0;right: 0;bottom: 0;left: 0;
  background-image: url("@/assets/images/checkin_sj.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}


.wrap {
  position: fixed;
  top: 0;right: 0;bottom: 0;left: 0;
  background-color: lightgrey;

  &>.title {
    margin-top: 40%;
    font-size: 20px;
    color: white;
  }
  &>.tip-wrap {
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    display: flex;align-items: center;justify-content: center;
    &>.tip-box {
      background-color: rgba(0, 0, 0, 0.8);
      padding: 8px 17px;
      border-radius: 4px;
      color: #eee;font-size: 12px;

      &>.btn {
        margin-top: 10px;
        color: white;
        font-size: 1.2em;
      }
    }
  }
}



</style>