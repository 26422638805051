<template>
<div class="wrap">
  <div class="title">错误</div>
  <div class="tip-wrap" v-if="error">
    <div class="main">
      {{error.msg}}
    </div>
    <div>
      <span>error_code:</span>
      <span>{{error.error_code}}</span>
    </div>
    <div>
      <span>request:</span>
      <span>{{error.request}}</span>
    </div>

  </div>
</div>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "AuthView",
  data(){
    return {
      error:undefined,
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_error().then(res=>{
        this.error = res
      }).catch(err=>{
        this.notice_warning(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.wrap {
  position: fixed;
  top: 0;right: 0;bottom: 0;left: 0;
  background-color: lightgrey;

  &>.title {
    margin-top: 40%;
    font-size: 20px;
  }
  &>.tip-wrap {
    .main {
      font-size: 16px;
    }
  }
}



</style>